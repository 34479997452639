var render = function () {
  var _vm$formSteps;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-new-campaign"
  }, [_c('div', {
    staticClass: "add-new-campaign__main"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "add-new-campaign__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "outline black",
    attrs: {
      "arrowIconLeft": true
    },
    on: {
      "click": function click($event) {
        _vm.$router.push({
          path: "/".concat(_vm.$route.path.includes('company') ? 'company' : 'fund', "/capital-rise?page=campaigns")
        });
      }
    }
  }, [_vm._v("Go back")]), _c('sygni-select', {
    attrs: {
      "options": _vm.languages.activeLanguages,
      "label": "Language",
      "allowEmpty": false,
      "addOptionBtn": true,
      "addOptionBtnText": "Manage languages"
    },
    on: {
      "addOption": _vm.openLangModal
    },
    model: {
      value: _vm.languages.selectedLanguage,
      callback: function callback($$v) {
        _vm.$set(_vm.languages, "selectedLanguage", $$v);
      },
      expression: "languages.selectedLanguage"
    }
  })], 1), _c('div', {
    staticClass: "add-new-campaign__box"
  }, [_c('router-view', {
    attrs: {
      "languages": _vm.languages.activeLanguages,
      "validation": _vm.$v,
      "selectedLanguage": _vm.languages.selectedLanguage
    }
  })], 1)], 1), _c('div', {
    staticClass: "add-new-campaign__sidebar"
  }, [_c('div', {
    staticClass: "add-new-campaign__sidebar-content"
  }, [_c('sygni-steps', {
    attrs: {
      "steps": _vm.formSteps,
      "currentStep": _vm.currentStepId
    }
  })], 1), _c('div', {
    staticClass: "add-new-campaign__sidebar-action"
  }, [_c('div', {
    staticClass: "add-new-campaign__sidebar-action-inner"
  }, [_c('div', {
    staticClass: "add-new-campaign__sidebar-action-buttons"
  }, [_c('sygni-rect-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.isActiveCampaign || _vm.isMarketplaceAccountant ? _vm.saveCampaignTooltipText : '',
      expression: "isActiveCampaign || isMarketplaceAccountant ? saveCampaignTooltipText : ''",
      modifiers: {
        "hover": true
      }
    }],
    class: ['outline', _vm.isActiveCampaign || _vm.isMarketplaceAccountant ? 'inactive' : 'black'],
    on: {
      "click": _vm.saveCampaign
    }
  }, [_vm._v("Save")]), _c('sygni-rect-button', {
    staticClass: "outline black",
    on: {
      "click": _vm.openLandingPageModal
    }
  }, [_vm._v("Preview")])], 1), _vm.hasId && !_vm.isActiveCampaign && !_vm.isMarketplaceAccountant ? _c('div', {
    staticClass: "add-new-campaign__sidebar-action-buttons"
  }, [_c('sygni-rect-button', {
    staticClass: "filled w-full red",
    on: {
      "click": _vm.activateCampaign
    }
  }, [_vm._v("Activate campaign")])], 1) : _vm._e(), _c('sygni-line', {
    staticClass: "grey mb-4 mt-4"
  }), _c('div', {
    staticClass: "add-new-campaign__sidebar-action-buttons"
  }, [_c('sygni-rect-button', {
    class: ['filled', _vm.currentStepId === 1 ? 'disabled' : 'black'],
    on: {
      "click": _vm.setPreviousStep
    }
  }, [_vm._v("Prev")]), _c('sygni-rect-button', {
    class: ['filled', _vm.currentStepId === ((_vm$formSteps = _vm.formSteps) === null || _vm$formSteps === void 0 ? void 0 : _vm$formSteps.length) ? 'disabled' : 'black'],
    on: {
      "click": _vm.setNextStep
    }
  }, [_vm._v("Next")])], 1)], 1)])]), _c('landing-page-modal', {
    attrs: {
      "isLoading": _vm.isLandingPageModalLoading,
      "campaignData": _vm.campaignData,
      "show": _vm.showLandingPageModal
    },
    on: {
      "close": _vm.closeLandingPageModal
    }
  }), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLangModal,
      expression: "showLangModal"
    }],
    attrs: {
      "header": "Choose languages",
      "confirmText": "Save languages",
      "overflow": false
    },
    on: {
      "cancel": _vm.closeLangModal,
      "confirm": _vm.saveLanguages
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('sygni-multi-select', {
          attrs: {
            "options": _vm.languageOptions,
            "multiple": true,
            "searchable": true,
            "display-values": true,
            "close-on-select": false,
            "allow-empty": false,
            "clear-on-select": false,
            "preserve-search": true,
            "label": "SELECTED LANGUAGES"
          },
          model: {
            value: _vm.languages.tempLanguages,
            callback: function callback($$v) {
              _vm.$set(_vm.languages, "tempLanguages", $$v);
            },
            expression: "languages.tempLanguages"
          }
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }